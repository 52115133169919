import { Turbo } from '@hotwired/turbo-rails'
import TurboPower from 'turbo_power'
import LocalTime from 'local-time'
import 'chartkick/chart.js'
import { Chart } from 'chart.js'
import gradient from 'chartjs-plugin-gradient'
import annotationPlugin from 'chartjs-plugin-annotation'

import '~/components'
import '~/controllers'

import 'balloon-css'
import '~/styles/application.css'

window.Turbo = Turbo
TurboPower.initialize(Turbo.StreamActions)
LocalTime.start()
Chart.register(gradient)
Chart.register(annotationPlugin)
